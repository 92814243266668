import React, { useEffect, useState, useRef } from 'react';
import Papa from 'papaparse';
import "./resetDataCSS.scss";
import { awsConfig } from '../constants/defaultValues';

const ResetData = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState(null);
    const [data, setData] = useState(null);
    const [deleteStatus, setDeleteStatus] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [emails, setEmails] = useState([]); // เก็บ email จากไฟล์ CSV
    const fileInputRef = useRef(null); // ใช้ useRef() แทน this.fileInput
    const [userIds, setUserIds] = useState([]); // ✅ เก็บ user_id จาก CSV
    const [isLoading, setIsLoading] = useState(false); // ✅ เพิ่ม state สำหรับ loading

    // ค้นหาข้อมูลโดยใช้ email ทีละคน
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${awsConfig.API.endpoints[0].endpoint}/searchEmail?email=${encodeURIComponent(email)}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.ok) {
                const result = await response.json();
                if (result.results && result.results.dataResult) {
                    setData(result.results.dataResult);
                } else {
                    setMessage('ไม่พบข้อมูลของอีเมลนี้');
                }
            } else {
                setMessage('Error: ' + response.statusText);
            }
        } catch (error) {
            setMessage('Error: ' + error.message);
        }
    };

    // อัปโหลดไฟล์ CSV
    const fileSelectedHandler = (event) => {
        console.log("File Input Event:", event.target.files);
    
        if (!event.target.files || event.target.files.length === 0) {
            console.error("❌ No file selected!");
            alert("กรุณาเลือกไฟล์ CSV");
            return;
        }
    
        const file = event.target.files[0];
    
        if (!file.name.endsWith(".csv")) {
            console.error("❌ Invalid file format, must be .csv");
            alert("กรุณาอัปโหลดไฟล์ CSV เท่านั้น");
            return;
        }
    
        console.log("📌 Selected File:", file.name);
        console.log("📌 File Type:", file.type);
        console.log("📌 File Size:", file.size);
    
        setSelectedFile(file);
        setEmails([]); // รีเซ็ตอีเมล
        setData(null); // รีเซ็ตข้อมูล
    
        // ✅ ใช้ FileReader อ่านไฟล์
        const reader = new FileReader();
        reader.onload = ({ target }) => {
            Papa.parse(target.result, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    console.log("✅ Parsed CSV Data:", results.data);
                    const extractedEmails = results.data.map((row) => row.email?.trim()).filter(Boolean);
                    console.log("✅ Extracted Emails:", extractedEmails);
                    setEmails(extractedEmails);
                    setUserIds(extractedEmails.length > 0 ? extractedEmails : []);
    
                    // ✅ เรียกค้นหาข้อมูล
                    handleSearchCSV(extractedEmails);
                },
                error: (error) => {
                    console.error("❌ Error parsing CSV:", error.message);
                }
            });
        };
    
        reader.readAsText(file);
    };
    
    
    

    // ✅ ค้นหาหลายอีเมลจาก CSV และเก็บ `user_id`
    const handleSearchCSV = async (emailsList) => {
        if (!emailsList || emailsList.length === 0) {
            setMessage("ไม่มีอีเมลในไฟล์ CSV");
            return;
        }

        try {
            const response = await fetch(`${awsConfig.API.endpoints[0].endpoint}/searchMultipleEmails`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ emails: emailsList }),
            });

            if (response.ok) {
                const result = await response.json();
                if (result.results && result.results.dataResult) {
                    setData(result.results.dataResult);

                    // ✅ ดึงเฉพาะ `user_id`
                    const extractedUserIds = result.results.dataResult.map(item => item.user_id);
                    setUserIds(extractedUserIds);
                    console.log("Extracted User IDs:", extractedUserIds);
                } else {
                    setMessage('ไม่พบข้อมูลของอีเมลที่ค้นหา');
                }
            } else {
                setMessage('Error: ' + response.statusText);
            }
        } catch (error) {
            setMessage('Error: ' + error.message);
        }
    };

    const deleteData = async (id, email) => {
        const isConfirmed = window.confirm(`คุณแน่ใจหรือไม่ที่ต้องการลบข้อมูลของ ${email}`);
        if (isConfirmed) {
            setDeleteStatus(false);
            try {
                const response = await fetch(`${awsConfig.API.endpoints[0].endpoint}/deleteData?id=${encodeURIComponent(id)}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });
    
                if (response.ok) {
                    setMessage(`ลบข้อมูล ${email} สำเร็จ`);
                    // ✅ อัปเดตตารางโดยลบ User ที่ถูกลบออกจาก state
                    setData((prevData) => prevData.filter(item => item.user_id !== id));
                } else {
                    setMessage('Error: ' + response.statusText);
                }
            } catch (error) {
                setMessage('Error: ' + error.message);
            }
            setDeleteStatus(true);
        }
    };

    // ✅ ฟังก์ชันลบข้อมูลทั้งหมดจาก CSV โดยใช้ `user_id`
    const deleteDataFromCSV = async () => {
        if (userIds.length === 0) {
            setMessage("กรุณาอัปโหลดไฟล์ CSV ก่อนทำการลบข้อมูล");
            return;
        }
    
        const isConfirmed = window.confirm(`คุณแน่ใจหรือไม่ที่ต้องการลบข้อมูลของ ${userIds.length} รายการ`);
        if (isConfirmed) {
            setIsLoading(true);
            setMessage("⏳ กำลังลบข้อมูล...");
    
            try {
                const response = await fetch(`${awsConfig.API.endpoints[0].endpoint}/deleteMultipleEmails`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ ids: userIds }),
                });
    
                if (response.ok) {
                    setMessage(`✅ ลบข้อมูลทั้งหมด ${userIds.length} รายการเรียบร้อย`);
                    setData([]); // ✅ เคลียร์ตารางข้อมูล
                    setEmails([]); // ✅ รีเซ็ตอีเมล
                    setUserIds([]); // ✅ รีเซ็ต userIds
                    setSelectedFile(null); // ✅ เคลียร์ไฟล์ CSV
                    fileInputRef.current.value = ""; // ✅ รีเซ็ต input file
                    setIsLoading(false);
                } else {
                    setMessage('❌ Error: ' + response.statusText);
                }
            } catch (error) {
                setMessage('❌ Error: ' + error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };
    


    useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage(null);
            }, 3000);
        }
    }, [message]);

    useEffect(() => {
        if (emails.length > 0) {
            setUserIds(emails); // ✅ อัปเดต userIds ใหม่
        }
    }, [emails]);

    return (
        <div className='box-reset-data'>
            <p className='message-delete' style={{ color: 'red' }}>
                ข้อมูลเกี่ยวกับวิดีโอและคะแนนของผู้ใช้จะถูกลบทั้งหมด
            </p>
            <p className='message-delete-data'>{message}</p>

            {/* ✅ ฟิลด์ค้นหา Email ทีละคน */}
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-8">
                        <input
                            className="form-control me-2"
                            type="search"
                            placeholder="ค้นหา Email"
                            aria-label="Search"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-outline-success" type="submit">ค้นหา</button>
                    </div>
                </div>
            </form>

            {/* ✅ แสดงผลข้อมูลที่ค้นหา */}
            {/* ✅ ตารางแสดงผล CSV ที่ค้นหา */}
            {data && data.length > 0 ? (
                <table className="table mt-5">
                    <thead>
                        <tr>
                            <th scope="col">User Id</th>
                            <th scope="col">Email</th>
                            <th scope="col">ชื่อ</th>
                            <th scope="col">นามสกุล</th>
                            <th scope="col">fb_group</th>
                            <th scope="col">facebook</th>
                            <th scope="col">display_name</th>
                            <th scope="col">ลบข้อมูล</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => (
                            <tr key={item.user_id}>
                                <th scope="row">{item.user_id}</th>
                                <td>{item.email}</td>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.fb_group}</td>
                                <td>{item.facebook}</td>
                                <td>{item.display_name}</td>
                                <td>
                                    <button
                                        type="button"
                                        className={deleteStatus ? "btn btn-danger" : "btn btn-secondary"}
                                        onClick={() => deleteStatus && deleteData(item.user_id, item.email)}
                                    >
                                        ลบข้อมูล
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className='mt-5'>No data available</p>
            )}
            <br/><br/>
            {/* ✅ ตัวอย่างรูปแบบไฟล์ CSV ที่ถูกต้อง */}
            <div className="csv-example mt-4">
                <h5>📌 ตัวอย่างรูปแบบไฟล์ CSV ที่ถูกต้อง</h5>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>test1@test.com</td>
                        </tr>
                        <tr>
                            <td>test2@test.com</td>
                        </tr>
                        <tr>
                            <td>test3@test.com</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* ✅ ปุ่มอัปโหลด CSV */}
            <h5>อัปโหลดไฟล์อีเมลสำหรับรีเซ็ต</h5>
            <input
                type="file"
                accept=".csv"
                onChange={(event) => fileSelectedHandler(event)}
                style={{ display: "none" }}
                ref={fileInputRef}
            />
            <div className="mb-5 col-lg-9 col-md-12">
                <div className="input-group mb-2">
                    <button
                        className="btn btn-dark"
                        onClick={() => fileInputRef.current.click()}
                        disabled={isLoading} // ✅ ปิดปุ่มขณะโหลด
                    >
                        อัปโหลด CSV
                    </button>
                    <input
                        type="text"
                        className="form-control"
                        value={selectedFile ? selectedFile.name : ""}
                        readOnly
                    />
                </div>
            </div>

            {/* ✅ ปุ่มรีเซ็ตข้อมูลจาก CSV พร้อม Loading */}
            <button
                className="btn btn-danger col-lg-2"
                onClick={deleteDataFromCSV}
                disabled={userIds.length === 0 || isLoading} // ✅ ปิดปุ่มถ้าไม่มี userIds หรือกำลังโหลด
            >
                {isLoading ? "⏳ กำลังลบข้อมูล..." : "รีเซ็ตข้อมูลจาก CSV"} {/* ✅ เปลี่ยนข้อความปุ่ม */}
            </button>
        </div>
    );
};

export default ResetData;
